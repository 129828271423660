module.exports = {
id:128,
title: 'Protecting Our Students from ICE, Recap of Today&rsquo;s Monroe UTLA Meeting',
date: '2025-01-23',
content: `<p>Because of our heavy immigrant and newcomer population, Monroe is Ground Zero on Immigration &amp; Customs Enforcement raids at schools. Roughly one out of every 15 newcomer secondary school students in LAUSD attends Monroe High School.</p>
<p>Tomorrow morning in your boxes we will have the following:</p>
<ul>
<li>An information sheet explaining the limitations on ICE coming onto our campus and what we as teachers can do if confronted with ICE</li>
<li>Buttons in English and Spanish which say &ldquo;You are welcome here&rdquo;/&ldquo;Eres bienvenido aqu&iacute;&rdquo;</li>
</ul>
<p>We also have posters which say <a target="_blank" href="https://drive.google.com/file/d/1lEAB9YrWlQJWT6Vu605U13snhygLC66H/view?usp=sharing">&ldquo;This classroom is a safe space for immigrants&rdquo;/&ldquo;Esta aula es un espacio seguro para inmigrantes&rdquo; </a>&ndash; if you want one to put on your door, simply reply to this message or stop by my classroom (F6) any time tomorrow.</p>
<h2>Leafleting to Tell Monroe Parents What Rights They Have If ICE Comes</h2>
<p>Next Wednesday before and after school we will be handing out the UTLA document <a target="_blank" href="https://drive.google.com/file/d/1mmWZtbc9krSEYm-uXgVjq5uABGZUTxE1/view?usp=sharing">&ldquo;Know your rights when facing immigration and customs enforcement&ldquo;</a> in both languages. <strong>We&rsquo;re looking for volunteers </strong>&ndash; to volunteer, simply reply to this message.</p>
<p>The morning shift will go from ~7:50-8:20 AM, the afternoon shift from ~3:43 to 4:10 PM. If you need to arrive late or leave early that&rsquo;s fine, we still want you with us.</p>
<h2>LAUSD &amp; UTLA on Same Page re: Immigrant Students</h2>
<p>In November, the LAUSD School board <a target="_blank" href="https://www.nbclosangeles.com/news/local/lausd-sanctuary-district-immigration-trump/3564520/">voted unanimously</a> to declare us a sanctuary district. At this point LAUSD and UTLA<em> share the same position</em> on immigration and our students. Moreover, we are in a sanctuary city in a sanctuary state.</p>
<p>The resolution calls for LAUSD to train teachers and staff about how they should respond if approached by federal immigration authorities&ndash;we&rsquo;ll have more on this in coming days.</p>
<h2>Change in ICE Policy Puts Our Students at Risk</h2>
<p>One important difference between today and the past is that in the past schools, as well as churches and hospitals, were considered &ldquo;sensitive&ldquo; areas where ICE would not go. The Trump administration announced this week that this limitation no longer exists.</p>
<h2>The Attack on Birthright Citizenship</h2>
<p>Perhaps because I&rsquo;m a Government teacher, many students have asked me about Trump&rsquo;s stated intention to eliminate birthright citizenship for the children of undocumented immigrants or people in the United States under temporary visas.</p>
<p>Trump&rsquo;s executive order is prospective not retroactive, and would only apply to babies born February 19, 2025 or later. Of course, if the courts decide that this prospective order passes constitutional muster, Trump could try to extend it retroactively, though success there would be very unlikely.</p>
<h2>Good News&ndash;Judge Blocks Trump&rsquo;s Birthright Citizenship Order</h2>
<p>Some good news, a judge has issued a restraining order blocking&ndash;albeit temporarily&ndash;Trump&rsquo;s order eliminating birthright citizenship.</p>
<p>The judge, a Reagan appointee, said what the Trump Administration is doing is &ldquo;blatantly unconstitutional&rdquo; and cannot be enforced.</p>
<p>In issuing the injunction, Seattle-based U.S. District Judge John Coughenour said:</p>
<p>"I am having trouble understanding how a member of the bar could state unequivocally that this order is constitutional&hellip;It just boggles my mind."</p>
<p>He noted:</p>
<p>&ldquo;I've been on the bench for over four decades. I can't remember another case where the question presented is as clear as this one.&rdquo;</p>
<p>Trump announced, of course, that he will be appealing.</p>
<p>Regarding the immigration issue. Monroe &amp; LAUSD, we will provide updates to you as events unfold.</p>`
}