module.exports = {
id:129,
title: 'UTLA Fighting for Major Overhaul to How & How Much We\'re Paid',
date: '2025-02-23',
content: `<p>UTLA is fighting for a major, far-reaching overhaul to our salary tables and how we're paid--come to the Monroe UTLA meeting in my room (F-6) Wednesday after school for more info. To join us online, <a href="https://docs.google.com/document/d/1aWj8x62lTBUKDN-BWGXh2uDvJZhNsVENGycdAkM8aqA/edit?usp=sharing">see our flier</a>.</p>
<p>Monroe UTLA Chair Lourdes Quevedo is a member of the UTLA bargaining team, attended the first two bargaining sessions this week, and will be explaining this and other innovative proposals UTLA is bringing forward.&nbsp;</p>
<h2>SPED Class-Size Violations: a New Approach</h2>
<p>Many SPED teachers have to contend with class sizes that break class-size caps. UTLA grieves these violations, but the process is slow. UTLA is taking a promising new approach to protecting SPED teachers&ndash;we&rsquo;ll be detailing it at Wednesday's Monroe UTLA meeting.</p>
<h2>LAUSD&rsquo;s Financial Position Remains Strong</h2>
<p>It is true that COVID funds were one-time money, but nevertheless, according to UTLA Organizing and Representation Director Brian McNamara, LAUSD&rsquo;s financial position is strong.&nbsp;</p>
<p>McNamara says that LAUSD closed the 2023-2024 school year with a $670 million surplus just for that school year. They are also sitting on a $6.4 billion reserve.</p>
<p>Also, on November 5 Measure US, which UTLA worked to support, passed. It authorizes LAUSD to issue up to $9 billion in bonds, with revenue going to <a href="https://ballotpedia.org/Los_Angeles_Unified_School_District,_California,_Measure_US,_Bond_Measure_(November_2024)">update school facilities</a>. A 55% majority was required to approve the measure&ndash;it passed 68%-32%.</p>
<p>While <a href="https://codes.findlaw.com/ca/education-code/edc-sect-41372/">California Education Code - EDC &sect; 41372</a> mandates that &ldquo;55 percent of the district's current expense of education&rdquo; be &ldquo;expended during each fiscal year for payment of salaries of classroom teachers&rdquo;, LAUSD, according to McNamara, spends only 45% on classroom teachers&rsquo; salaries. LAUSD is able to get away with this because they receive a waiver from the Los Angeles County Office of Education. UTLA is fighting the waiver.</p>
<h2>We Have More Immigration Posters &amp; Buttons Available</h2>
<p>Because of our heavy immigrant and newcomer population, Monroe is Ground Zero on Immigration &amp; Customs Enforcement raids at schools. Roughly one out of every 15 newcomer secondary school students in LAUSD attends Monroe High School. We are encouraging teachers to:</p>
<ol>
<li>Put posters which say <a href="https://drive.google.com/file/d/1lEAB9YrWlQJWT6Vu605U13snhygLC66H/view?usp=sharing">&ldquo;This classroom is a safe space for immigrants&rdquo;/&ldquo;Esta aula es un espacio seguro para inmigrantes&rdquo;</a> on your doors</li>
<li>Wear buttons in English and/or Spanish which say &ldquo;You are welcome here&rdquo;/&ldquo;Eres bienvenido aqu&iacute;&rdquo;</li>
</ol>
<p>To get either or both, simply reply to this message, tell me what you want and how many, and we will deliver them to you.</p>
<h2>Another Challenge Faced by Schools &amp; Districts That Serve Large Immigrant Populations</h2>
<p>We were pleased at the large turnout for parent leafleting on Wednesday January 29th, particularly in the morning. As you may recall, we were handing out leaflets which advised immigrant parents of their rights in the face of potential Immigration and Customs Enforcement raids. Sadly, the number of parents was significantly down, the lowest I think I've ever seen while doing leafleting over the years.</p>
<p>Absences attributable to fears over immigration enforcement and immigration issues obviously hurt Monroe more than most other schools, and they hurt all of LAUSD disproportionately because in California education funding is tied to attendance rather than to enrollment.&nbsp;</p>
<p>In general, students of low socioeconomic status face many extra challenges, challenges that reduce attendance rates. Fear of immigration and immigration raids has made this worse. However, when students are absent, their schools and districts lose funding for them, even though their costs remain almost the same, regardless of daily attendance.</p>
<p>Most states have adopted an enrollment-based or partially enrollment-based system, but California and Texas are among a half dozen that still apportion school funding by ADA. In recent years, legislators in both states have tried unsuccessfully to change this.</p>
<p>LAUSD, where 76% of our students live in poverty, has been supportive of these legislative efforts. Kelly Gonez, a member of the LAUSD School Board, explains, &ldquo;We lose about $200 million annually because of the difference between enrollment and average daily attendance.&rdquo;&nbsp;</p>
<p>For more information, see <a href="https://www.realcleareducation.com/articles/2024/04/02/helping_our_most_challenged_schools_1022460.html">Helping Our Most Challenged Schools: Tie Funding to Enrollment, not Attendance</a> (<em>RealClearEducation.com</em>, 4/2/24).</p>
<h2>Progress on Nurses &amp; Librarians</h2>
<p>In 2019 we fought for more school nurses. California faces a huge nursing shortage&ndash;according to the UC San Francisco Health Workforce Research Center on Long-Term Care, our state is short <em>36,000</em> licensed nurses.&nbsp;</p>
<p>However, according to UTLA AFT Vice President Julie Van Winkle, there are now only 34 nursing slots open throughout LAUSD.</p>
<p>In 2019 we also fought for more full-time teacher librarians, and today there are teacher librarians in every secondary school in LAUSD. By contrast, only 90 of Chicago Public Schools&rsquo; 513 schools have a full-time librarian.&nbsp;</p>
<p>According to a <a href="https://chicago.suntimes.com/education/2022/7/18/23201078/cps-public-schools-librarians-misinformation-budget-cuts-pandemic-literacy-reading-books">2022 analysis</a> by the <em>Chicago Sun-Times</em>, &ldquo;there are librarians at only 10% of schools where Black students are the largest percentage of the student body.&rdquo;</p>
<h2>UTLA Seeks 2-Year Contract, Instead of the Usual 3-Year Agreement&ndash;Here&rsquo;s Why</h2>
<p>One of the problems facing labor unions throughout the United States is that we are often unnecessarily fragmented, and the expiration dates of the unions&rsquo; contracts rarely line up. This saps the labor movement of the power that could be gained through bargaining and potentially striking on a larger scale.</p>
<p>In LAUSD, for example, the labor force is divided up between eight different unions under separate contracts. The only exception is when we bargain for healthcare.&nbsp;</p>
<p>In current negotiations, UTLA is looking to negotiate a two-year contract instead of a three-year contract. This would expire June 30, 2027 and thus line us up with the contract expirations of the dozen other largest teacher union locals in California so we can better coordinate bargaining and labor action in 2027 and 2028.</p>`
}